import React from "react";
import { createRoot } from "react-dom/client";

import "./index.css";
import "./assets/fonts/inter-v12-latin-300.woff2";
import "./assets/fonts/inter-v12-latin-600.woff2";
import "./assets/fonts/inter-v12-latin-regular.woff2";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { inject } from "@vercel/analytics";

const root = document.getElementById("root");
const rootInstance = createRoot(root);
rootInstance.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
inject();
