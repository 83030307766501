import styles from "./tag.module.css";

function Tag(props) {
  return (
    <div
      className={styles.chipContainer}
      style={{
        marginLeft: props.aligned ? "-8px" : undefined,
        backgroundColor: props.color ? props.color : undefined,
      }}
    >
      <span
        style={{
          color: props.inverse
            ? "var(--text-color-inverse)"
            : "var(--text-color)",
        }}
      >
        {props.children}
      </span>
    </div>
  );
}

export default Tag;
