import styles from "./header.module.css";
import avatar from "../../assets/avatar.png";
import avatarWebp from "../../assets/avatar.webp";
import { NavLink } from "react-router-dom";
import useScrollPosition from "../../hooks/useScrollPosition";
import useMediaQuery from "../../hooks/mediaQueries";
import Link from "../link/Link";
import { Link as ReactLink } from "react-router-dom";
import { useState } from "react";
import { links } from "../../data/workData";
import Image from "../image/Image";
import Menu from "../menu/Menu";
import ResponsiveLayout from "../responsive-layout/responsive-layout";
import Badge from "../badge/badge";

function Header(props) {
  const scroll = useScrollPosition();
  const isMobile = useMediaQuery("(max-width: 640px)");
  const showNavButton = isMobile ? 680 : 881;

  const [menu, setMenu] = useState(false);
  const handleMenu = () => setMenu(!menu);

  const navItems = (
    <>
      {links.map((value, index) => (
        <li key={index}>
          {value.new ? (
            <Badge color="var(--link-color)">
              <NavLink
                to={value.url}
                className={({ isActive }) =>
                  isActive
                    ? `${styles.active} ${styles.headerLink}`
                    : styles.headerLink
                }
              >
                {value.label}
              </NavLink>
            </Badge>
          ) : (
            <NavLink
              to={value.url}
              className={({ isActive }) =>
                isActive
                  ? `${styles.active} ${styles.headerLink}`
                  : styles.headerLink
              }
            >
              {value.label}
            </NavLink>
          )}
        </li>
      ))}
    </>
  );
  return (
    <header style={{ position: "sticky", top: "0", zIndex: 9999 }}>
      <div className={styles.headerContainer}>
        <ResponsiveLayout>
          <div className={styles.headerContent}>
            <ReactLink className={styles.headerLogoContainer} to={"/"}>
              <Image
                avoidLazy
                src={avatar}
                srcWebp={avatarWebp}
                alt="avatar"
              ></Image>
              <h1 className={styles.headerLogo}>Alex Bueno</h1>
            </ReactLink>

            {isMobile && (
              <button
                className={styles.responsiveButton}
                aria-haspopup="true"
                aria-expanded={menu}
                onClick={handleMenu}
                aria-label="Menu"
              >
                {menu ? (
                  <svg
                    className={styles.icon}
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    width="20"
                  >
                    <path d="M6.062 15 5 13.938 8.938 10 5 6.062 6.062 5 10 8.938 13.938 5 15 6.062 11.062 10 15 13.938 13.938 15 10 11.062Z" />
                  </svg>
                ) : (
                  <svg
                    className={styles.icon}
                    xmlns="http://www.w3.org/2000/svg"
                    height="20"
                    width="20"
                  >
                    <path d="M3 14.5V13h14v1.5Zm0-3.75v-1.5h14v1.5ZM3 7V5.5h14V7Z" />
                  </svg>
                )}
              </button>
            )}

            {!isMobile && (
              <nav className={styles.headerNav} role="navigation">
                <ul className={styles.headerUl}>
                  {navItems}
                  {props.home === true && scroll >= showNavButton ? (
                    <Link
                      small
                      isPrimary
                      isButton
                      href="mailto:hey.alexbuenodesign@gmail.com"
                    >
                      Get in touch
                    </Link>
                  ) : (
                    ""
                  )}
                </ul>
              </nav>
            )}
          </div>
        </ResponsiveLayout>
      </div>

      {menu ? <Menu /> : ""}
    </header>
  );
}

export default Header;
