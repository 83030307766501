import styles from "./image.module.css";

function Image(props) {
  const aspectRatio = props.aspectRatio ?? undefined;

  return (
    <picture className={styles.picture}>
      <source srcSet={props.srcWebp} type="image/webp"></source>
      <source srcSet={props.src} type="image/png" />
      <img
        style={{
          borderRadius: props.borderRadius,
          aspectRatio: props.aspectRatio ? `${aspectRatio}` : undefined,
        }}
        className={`${styles.img} 
      ${props.radiusSmall ? styles.radiusSmall : ""} ${props.className} ${
          props.squared ? styles.squared : ""
        } `}
        width={props.width ?? "100%"}
        height={props.height ?? "auto"}
        alt={props.alt}
        src={props.src}
        loading={props.avoidLazy ? "eager" : "lazy"}
      />
    </picture>
  );
}

export default Image;
