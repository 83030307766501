import Section from "../components/section/Section";
import AppContainer from "../components/appContainer/AppContainer";
import RowList from "../components/rowList/RowList";
import { posts } from "../data/blogData";
import Hero from "../components/hero/Hero";
import useMediaQuery from "../hooks/mediaQueries";
import Card from "../components/card/Card";
import Button from "../components/button/Button";
import podcast from "../assets/podcast.jpeg";
import podcastWebp from "../assets/podcast.webp";
import adevinta from "../assets/adevinta.jpg";
import adevintaWebp from "../assets/adevinta.webp";
import Image from "../components/image/Image";
import Icon from "../components/Icon/Icon";
import miniature from "../assets/miniature.png";

import { Helmet } from "react-helmet";
import ResponsiveLayout from "../components/responsive-layout/responsive-layout";
import { explorations } from "../data/explorations";

function Home() {
  const isDesktop = useMediaQuery("(min-width: 640px)");
  const latestPosts = [posts[posts.length - 1], posts[posts.length - 2]];
  document.title = "Alex Bueno";

  return (
    <>
      <Helmet>
        <meta name="title" content="Alex Bueno" />
        <meta
          name="description"
          content="I'm a digital designer interested in design systems and front-end
            development."
        />
        <meta property="og:title" content="Alex Bueno" />
        <meta
          property="og:description"
          content="I'm a digital designer interested in design systems and front-end
            development"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Alex Bueno" />
        <meta
          property="twitter:description"
          content="I'm a digital designer interested in design systems and front-end
          development."
        />
        <meta
          property="og:image"
          content={`https://www.alexbuenodesign.com/${miniature}`}
        />
        <meta
          property="twitter:image"
          content={`https://www.alexbuenodesign.com/${miniature}`}
        />
      </Helmet>

      <AppContainer home>
        <Hero></Hero>
        <Section title="Latest articles" hasLink link="See all →" href="/blog">
          <ul
            style={{
              display: "grid",
              gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
              gap: "8px",
              margin: 0,
              padding: 0,
            }}
          >
            {latestPosts.map((value, index) => (
              <RowList
                key={index}
                isDynamic
                title={value.title}
                date={value.date}
                readTime={value.readTime}
                url={`blog/${value.url}`}
                detail={value.detail}
                target={value.external}
                image={value.miniature}
                srcWebp={value.miniatureWebp}
                alt={value.alt}
              />
            ))}
          </ul>
        </Section>
        <Section title="Just chatting">
          <Card
            image={
              isDesktop ? (
                <div style={{ width: 150 }}>
                  <Image
                    squared
                    radiusSmall
                    width={197.97}
                    height={197.97}
                    src={adevinta}
                    srcWebp={adevintaWebp}
                    alt={"Supper session con Silvia y Yayo de Telefónica Team"}
                  ></Image>
                </div>
              ) : undefined
            }
            imagePosition="left"
            date="May 17, 2023"
            language="es"
            title="Supper session con Silvia y Yayo de Telefónica Team"
            description="AdevintaSpainTech | DesignOps Weekly Meeting"
            actions={
              <Button
                small
                isLink
                external
                icon={<Icon name="twitch" viewBox="0 0 2400 2800" />}
                label="Watch on Twitch"
                variant="secondary"
                to="https://www.twitch.tv/videos/1822043340"
              ></Button>
            }
          ></Card>
          <Card
            image={
              isDesktop ? (
                <div style={{ width: 150 }}>
                  <Image
                    squared
                    radiusSmall
                    width={197.97}
                    height={197.97}
                    src={podcast}
                    srcWebp={podcastWebp}
                    alt={"Chimichurri Code podcast logo"}
                  ></Image>
                </div>
              ) : undefined
            }
            imagePosition="left"
            date="Mar 1, 2023"
            language="es"
            title="Charlando con #DesignOps de Telefónica"
            description="Chimichurri Code | 4x01"
            actions={
              <Button
                small
                isLink
                external
                icon={<Icon name="spotify" viewBox="0 0 24 24" />}
                label="Listen in Spotify"
                variant="secondary"
                to="https://open.spotify.com/episode/3VAbJN2dWdWIc4PJk90e5J"
              ></Button>
            }
          ></Card>
        </Section>
        {/*
        <Section title="Explorations">
          <div
            style={{
              display: "grid",
              gridTemplateColumns: isDesktop ? "1fr 1fr" : "1fr",
              gap: "8px",
            }}
          >
            {explorations.map((exploration, index) => (
              <Card
                image={
                  <Image
                    radiusSmall
                    width={197.97}
                    height={197.97}
                    aspectRatio="16/9"
                    src={podcast}
                    srcWebp={podcastWebp}
                    alt={exploration.alt}
                  ></Image>
                }
                date={exploration.date}
                title={exploration.title}
                description={exploration.description}
                actions={
                  <Button
                    small
                    isLink
                    external
                    icon={
                      <Icon
                        name={exploration.actions.icon}
                        viewBox={exploration.actions.viewBox || "0 0 24 24"}
                      />
                    }
                    label={exploration.actions.label}
                    variant="secondary"
                    to={exploration.actions.to}
                  ></Button>
                }
              ></Card>
            ))}
          </div>
        </Section>
              */}
      </AppContainer>
    </>
  );
}

export default Home;
