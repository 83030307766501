import styles from "./rowList.module.css";
import Link from "../link/Link";
import Flex from "../flex/Flex";
import Chip from "../chip/Chip";
import Icon from "../Icon/Icon";
import Image from "../image/Image";
import useMediaQuery from "../../hooks/mediaQueries";
import Circle from "../circle/circle";

function RowList(props) {
  const isDesktop = useMediaQuery("(max-width: 640px)");
  return (
    <>
      {props.isDynamic ? (
        <li className={styles.li}>
          <Link isBoxed isReactLink href={`${props.url}`} target={props.target}>
            <Flex
              direction={
                props.imageLeft ? (isDesktop ? "column" : "row") : "column"
              }
              gap={24}
            >
              {props.image ? (
                <div
                  style={{
                    maxWidth: props.imageLeft
                      ? isDesktop
                        ? undefined
                        : 250
                      : undefined,
                  }}
                >
                  <Image
                    radiusSmall
                    className={styles.image}
                    src={props.image}
                    width={props.imageWidth}
                    height={props.imageHeight}
                    srcWebp={props.srcWebp}
                    alt={props.alt}
                    avoidLazy={props.useLazy ? false : true}
                  ></Image>
                </div>
              ) : (
                <></>
              )}
              <Flex direction="column" gap={16}>
                {props.date ? (
                  <Chip>
                    <span>{`${props.date} ${
                      props.readTime ? "·" + " " + props.readTime : ""
                    }`}</span>
                  </Chip>
                ) : (
                  <></>
                )}

                <Flex direction="column" gap={8}>
                  <Flex justify="space-between" gap={8} align="center">
                    <h3 className={styles.listHeading}>{props.title}</h3>
                    {props.target && !props.image && (
                      <Icon width={18} name="externalLink"></Icon>
                    )}
                  </Flex>
                  <span className={styles.listDetail}>{props.detail}</span>
                </Flex>
              </Flex>
            </Flex>
            {props.target && props.image ? (
              <div className={styles.externalIcon}>
                <Circle>
                  <Icon width={18} name="externalLink"></Icon>
                </Circle>
              </div>
            ) : (
              <></>
            )}
          </Link>
        </li>
      ) : (
        <li key={props.index} className={styles.listLi}>
          <Flex gap={8} direction="column">
            <div className={styles.listHeader}>
              <h3 className={styles.listHeading}>{props.title}</h3>{" "}
              <span className={styles.listDescription}>{props.date}</span>
            </div>
            <div className={styles.listDetail}>
              {props.url === undefined ? (
                <span>{props.detail}</span>
              ) : (
                <Link href={props.url}>{props.detail}</Link>
              )}
            </div>
          </Flex>
        </li>
      )}
    </>
  );
}

export default RowList;
