import styles from "./appContainer.module.css";
import Header from "../header/header";
import useMediaQuery from "../../hooks/mediaQueries";
import ViewTools from "../viewTools/ViewTools";
import ScrollToTop from "../../hooks/scrollToTop";

function AppContainer(props) {
  const isMobile = useMediaQuery("(min-width: 640px)");

  return (
    <>
      {props.noHeader ? (
        <>
          <ScrollToTop />

          <main
            data-theme={props.dataTheme}
            className={`${styles.appContainer} ${
              isMobile ? styles.gutterM : styles.gutterD
            }`}
          >
            {props.children}
          </main>
        </>
      ) : (
        <>
          <ScrollToTop />
          <ViewTools></ViewTools>
          <Header home={props.home} />
          <main
            data-theme={props.dataTheme}
            className={`${styles.appContainer} ${
              isMobile ? styles.gutterM : styles.gutterD
            }`}
          >
            {props.children}
          </main>
        </>
      )}
    </>
  );
}

export default AppContainer;
