import AmbientSwitch from "../ambientSwitch/AmbientSwitch";
import ResponsiveLayout from "../responsive-layout/responsive-layout";
import ThemeToggle from "../themeToggle/ThemeToggle";
import styles from "./viewTools.module.css";

function ViewTools(props) {
  return (
    <div className={styles.container}>
      <ResponsiveLayout>
        <div className={styles.content}>
          <AmbientSwitch></AmbientSwitch>
          <ThemeToggle></ThemeToggle>
        </div>
      </ResponsiveLayout>
    </div>
  );
}

export default ViewTools;
