import styles from "./button.module.css";
import { Link as ReactLink } from "react-router-dom";

function Button(props) {
  const getVariant = (props) => {
    switch (props.variant) {
      case "primary":
        return styles.buttonPrimary;
      case "primary3D":
        return styles.buttonPrimary3D;
      case "secondary":
        return styles.buttonSecondary;
      case "tertiary":
        return styles.buttonTertiary;
      default:
        return null;
    }
  };

  const variant = getVariant(props);

  return (
    <>
      {props.isLink ? (
        <ReactLink
          target={props.external ? "_blank" : undefined}
          className={`
          ${styles.button}
          ${props.small && styles.small}
          ${variant}
        `}
          to={props.to}
        >
          {props.icon &&
            (props.iconPosition === "leading" ||
              props.iconPosition === undefined) && <>{props.icon}</>}
          <span className={styles.label}>{props.label}</span>
          {props.iconPosition === "trailing" && props.icon && <>{props.icon}</>}
        </ReactLink>
      ) : (
        <button
          className={`
          ${styles.button}
          ${props.small && styles.small}
          ${props.fullWidth && styles.fullWidth}
          ${variant}

      `}
          onPress={props.onPress}
        >
          {props.iconPosition !== undefined ? (
            props.iconPosition === "leading" ? (
              <>{props.icon}</>
            ) : null
          ) : (
            <>{props.icon}</>
          )}
          <span>{props.label}</span>
          {props.iconPosition === "trailing" && <>{props.icon}</>}
        </button>
      )}
    </>
  );
}

export default Button;
