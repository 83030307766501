import { lazy, Suspense } from "react";
import Spinner from "../../components/spinner/spinner";

const Lost = lazy(() => import("./Lost-in-customization/Post"));
const State = lazy(() => import("./state-and-main/Post"));
const Good = lazy(() => import("./The-good-the-bad.and-the-toggle/Post"));
const Euclidean = lazy(() => import("./The-euclidean-design-model/Post"));
const MisticaTokens = lazy(() => import("./mistica-tokens/Post"));

const fallback = (
  <div
    style={{
      height: "calc(100vh - 400px)",
      width: "100%",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <Spinner></Spinner>
  </div>
);

export const Posts = {
  toggle: (
    <Suspense fallback={fallback}>
      <Good />
    </Suspense>
  ),
  state: (
    <Suspense fallback={fallback}>
      <State />
    </Suspense>
  ),
  euclidean: (
    <Suspense fallback={fallback}>
      <Euclidean />
    </Suspense>
  ),
  lost: (
    <Suspense fallback={fallback}>
      <Lost />
    </Suspense>
  ),
  misticaTokens: (
    <Suspense fallback={fallback}>
      <MisticaTokens />
    </Suspense>
  ),
};

export const Miniatures = {
  toggle: require("./The-good-the-bad.and-the-toggle/img/miniature.jpg"),
  state: require("./state-and-main/img/miniature.jpg"),
  euclidean: require("./The-euclidean-design-model/img/miniature.jpg"),
  lost: require("./Lost-in-customization/img/miniature.jpg"),
  pipeline: require("./Design-tokens-automation-pipeline/img/miniature.jpg"),
  accordion: require("./Accordions/img/miniature.jpg"),
  misticaTokens: require("./mistica-tokens/img/default/miniature.jpg"),
};

export const MiniaturesWebp = {
  lost: require("./Lost-in-customization/img/miniature.webp"),
  euclidean: require("./The-euclidean-design-model/img/miniature.webp"),
  toggle: require("./The-good-the-bad.and-the-toggle/img/miniature.webp"),
  state: require("./state-and-main/img/miniature.webp"),
  pipeline: require("./Design-tokens-automation-pipeline/img/miniature.webp"),
  accordion: require("./Accordions/img/miniature.webp"),
  misticaTokens: require("./mistica-tokens/img/webp/miniature.webp"),
};
