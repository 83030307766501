import styles from "./section.module.css";
import Link from "../link/Link";
import ResponsiveLayout from "../responsive-layout/responsive-layout";

function Section(props) {
  const Section = props.as || "section";
  return (
    <Section>
      <ResponsiveLayout>
        <div
          className={props.hasAnchor ? styles.anchor : styles.container}
          id={props.id}
        >
          {(() => {
            if (props.title === undefined) {
              return <>{props.children}</>;
            } else {
              return (
                <>
                  {props.hasLink ? (
                    <div className={styles.hasLink}>
                      <h2 className={styles.title}>{props.title}</h2>
                      <Link isReactLink href={props.href}>
                        {props.link}
                      </Link>
                    </div>
                  ) : (
                    <h2 className={styles.title}>{props.title}</h2>
                  )}
                  {props.children}
                </>
              );
            }
          })()}
        </div>
      </ResponsiveLayout>
    </Section>
  );
}

export default Section;
