import styles from "./link.module.css";
import { Link as ReactLink } from "react-router-dom";

function Link(props) {
  return (
    <>
      {props.isReactLink ? (
        <ReactLink
          target={props.target ? "_blank" : ""}
          className={`
        ${props.isButton ? styles.linkButton : styles.link}
        ${props.isPrimary ? styles.linkButtonPrimary : ""}
        ${props.isBoxed ? styles.linkBoxed : ""}
    `}
          to={props.href}
        >
          {props.children}
        </ReactLink>
      ) : (
        <a
          className={`
          ${props.small ? styles.small : ""}
          ${props.isButton ? styles.linkButton : styles.link}
          ${props.isPrimary ? styles.linkButtonPrimary : ""}
          ${props.isBoxed ? styles.linkBoxed : ""}
      `}
          target="_blank"
          rel="noreferrer"
          href={props.href}
          role={props.isButton ? "button" : undefined}
        >
          {props.children}
        </a>
      )}
    </>
  );
}

export default Link;
