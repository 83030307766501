import styles from "./badge.module.css";

function Badge(props) {
  return (
    <div className={styles.container}>
      <div className={styles.badge} style={{ background: props.color }}></div>
      {props.children}
    </div>
  );
}

export default Badge;
