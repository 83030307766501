import Icon from "../Icon/Icon";
import Chip from "../chip/Chip";
import styles from "./card.module.css";
import Flex from "../flex/Flex";

function Card(props) {
  return (
    <div
      className={
        props.highlighted
          ? `${styles.container} ${styles.highlighted}`
          : `${styles.container}`
      }
      style={{
        flexDirection: props.imagePosition === "left" ? "row" : "column",
      }}
    >
      {props.image && <div className={styles.image}>{props.image}</div>}
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <Flex direction="row" gap={4}>
            {props.date && <Chip>{props.date}</Chip>}
            {props.language && (
              <Chip>
                <Icon name="language" viewBox="0 96 960 960" /> {props.language}
              </Chip>
            )}
          </Flex>
          <span className={styles.title}>{props.title}</span>
          <span className={styles.description}>{props.description}</span>
        </div>
        <div className={styles.actions}>{props.actions}</div>
      </div>
    </div>
  );
}

export default Card;
