import styles from "./hero.module.css";
import Link from "../link/Link";
import Button from "../button/Button";
import ResponsiveLayout from "../responsive-layout/responsive-layout";

function Hero() {
  return (
    <section className={styles.heroContainer}>
      <ResponsiveLayout>
        <div className={styles.heroContent}>
          <h2 className={styles.heroSectionTitle}>About me</h2>
          <p className={styles.heroTitle}>
            I'm a digital designer interested in design systems and front-end
            development.
          </p>
          <p className={styles.heroDescription}>
            Currently working as a systems designer in Telefónica's{" "}
            <Link href="https://brandfactory.telefonica.com/d/iSp7b1DkYygv">
              Mística
            </Link>{" "}
            design system. Born and raised in{" "}
            <Link href="https://en.wikipedia.org/wiki/Asturias">Asturias.</Link>
          </p>

          <div className={styles.heroButtonsLayout}>
            <Button
              label="Get in touch"
              isLink
              variant="primary"
              to="mailto:hey.alexbuenodesign@gmail.com"
            ></Button>
            <Button
              label="More about me"
              isLink
              variant="tertiary"
              to={`/about`}
            ></Button>
          </div>
        </div>
      </ResponsiveLayout>
    </section>
  );
}

export default Hero;
