import { lazy, Suspense } from "react";

import correos from "../pages/work/Correos/img/correos.png";
import halstack from "../assets/work/halstack/miniature.png";
import descodificando from "../pages/work/Descodificando/img/poster_out.jpg";
import retrato from "../pages/work/retrato/img/miniature.png";
import octotype from "../pages/work/octotype/img/miniature.jpg";
import misticaTokens from "../pages/work/Mistica-tokens/img/miniature.webp";
import misticaWrapped2023 from "../pages/work/external-links/mistica-wrapped-2023.png";

import figmaWrapper from "../pages/snippets/figma-wrapper/img/miniature.png";

const Halstack = lazy(() => import("../pages/work/halstack/Halstack"));
const Correos = lazy(() => import("../pages/work/Correos/Correos"));
const Descodificando = lazy(() =>
  import("../pages/work/Descodificando/Descodificando")
);
const Retrato = lazy(() => import("../pages/work/retrato/retrato"));
const MisticaTokens = lazy(() =>
  import("../pages/work/Mistica-tokens/misticaTokens")
);
const Octotype = lazy(() => import("../pages/work/octotype/page"));

// Snippets

const FigmaWrapper = lazy(() =>
  import("../pages/snippets/figma-wrapper/snippet")
);
const CircularSlider = lazy(() =>
  import("../pages/snippets/circular-slider/snippet")
);
const NoiseBackground = lazy(() =>
  import("../pages/snippets/noise-background/snippet")
);

export const links = [
  { label: "Home", url: "/" },
  { label: "Work", url: "/work" },
  { label: "Snippets", url: "/snippets", new: true },
  { label: "Blog", url: "/blog" },
];

export const work = [
  {
    title: "Mistica wrapped 2023",
    type: "Digital projects",
    date: "2024",
    detail: "Wrapping the year 2023 of Mistica design system.",
    url: "https://mistica-design.vercel.app/wrapped-2023",
    miniature: misticaWrapped2023,
    external: true,
  },
  {
    title: "Korochef",
    type: "Digital projects",
    date: "2023",
    detail: "Vegan recipes catalog for a friend build with Astro.",
    url: "https://korochef.vercel.app/",
    miniature: octotype,
    external: true,
  },
  {
    title: "Octotype",
    type: "Digital projects",
    date: "2023",
    detail:
      "A blogging platform for developers to easily create and publish stories using GitHub Issues as a CMS.",
    url: "https://octotype.app/",
    component: (
      <Suspense>
        <Octotype />
      </Suspense>
    ),
    miniature: octotype,
    external: true,
  },
  {
    title: "Mística tokens",
    type: "Digital projects",
    date: "2023",
    detail: "A tool to review token changes in Mística design system.",
    url: "https://mistica-design.vercel.app/tokens-map?branch=production&skin=movistar&tokenType=color&activeColor=undefined",
    component: (
      <Suspense>
        <MisticaTokens />
      </Suspense>
    ),
    miniature: misticaTokens,
    external: true,
  },
  {
    title: "Halstack design system",
    type: "Digital projects",
    date: "2021",
    detail: "DXC's Assure design system.",
    url: "https://developer.dxc.com/halstack",
    miniature: halstack,
    external: true,
  },
  {
    title: "VUI for Correos Express",
    type: "Digital projects",
    date: "2019",
    detail: "Voice interface design for a shipping company.",
    url: "vui-for-correos-express",
    component: (
      <Suspense>
        <Correos />
      </Suspense>
    ),
    miniature: correos,
  },
];

export const snippets = [
  {
    title: "Figma wrapper",
    date: "2024",
    detail:
      "Wrap your components in a Figma-like frame that automatically calculates the size of its children.",
    url: "figma-wrapper",
    component: (
      <Suspense>
        <FigmaWrapper />
      </Suspense>
    ),
    miniature: figmaWrapper,
  },
  {
    title: "Noise background",
    date: "2024",
    detail:
      "A noise background generated with feTurbulence and feDisplacementMap filters.",
    url: "noise-background",
    component: (
      <Suspense>
        <NoiseBackground />
      </Suspense>
    ),
    miniature: figmaWrapper,
  },
];

export const education = [
  {
    title: "User experience design",
    date: "2021-2023",
    detail: "Master's degree - UNIR",
  },
  {
    title: "Graphic design",
    date: "2012-2016",
    detail: "Degree - ESAPA",
  },
];

export const experience = [
  {
    title: "Design engineer",
    date: "Current",
    detail: "Telefónica",
    url: "https://www.telefonica.com/en/",
  },
  {
    title: "Lead Product designer",
    date: "2021- 2022",
    detail: "DXC Technology",
    url: "https://dxc.com/us/en",
  },
  {
    title: "Design team lead",
    date: "2019-2021",
    detail: "Capgemini",
    url: "https://www.capgemini.com/",
  },
  {
    title: "Web designer",
    date: "2016-2019",
    detail: "Freelance",
  },
];
