import styles from "./themeToggle.module.css";
import Icon from "../Icon/Icon";
import { useContext, useEffect } from "react";
import { ThemeContext } from "../../App";

function ThemeToggle(props) {
  const { theme, setTheme } = useContext(ThemeContext);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);
  return (
    <>
      <div className={styles.container}>
        <Icon
          prefix
          name={(() => {
            if (theme === "system") {
              return "system";
            } else if (theme === "light") {
              return "light";
            } else {
              return "dark";
            }
          })()}
        ></Icon>
        <select
          className={styles.select}
          name="theme"
          id="theme"
          value={theme}
          onChange={(e) => {
            setTheme(e.target.value);
          }}
        >
          <option value="system">System</option>
          <option value="dark">Dark</option>
          <option value="light">Light</option>
        </select>
        <Icon suffix name="expand" />
      </div>
    </>
  );
}

export default ThemeToggle;
