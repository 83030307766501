import styles from "./circle.module.css";
import useTheme from "../../hooks/useTheme";

function Circle(props) {
  const isDark = useTheme() === "dark";

  return (
    <div
      className={`${isDark ? styles.circleDark : styles.circleLight} ${
        styles.circleContainer
      }`}
    >
      {props.children}
    </div>
  );
}

export default Circle;
