import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../App";

const useTheme = () => {
  const { theme } = useContext(ThemeContext);
  const initialState = window.matchMedia("(prefers-color-scheme: dark)").matches
    ? "dark"
    : "light";

  const [mode, setMode] = useState(initialState);
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => e.matches && setMode("dark"));

    theme === "dark" && setMode("dark");
    theme === "system" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches &&
      setMode("dark");

    window
      .matchMedia("(prefers-color-scheme: light)")
      .addEventListener("change", (e) => e.matches && setMode("light"));
    theme === "light" && setMode("light");
    theme === "system" &&
      window.matchMedia("(prefers-color-scheme: light)").matches &&
      setMode("light");
  }, [theme]);

  return mode;
};

export default useTheme;
