import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Home from "./pages/Home";
import { useState, createContext } from "react";
import styles from "./app.module.css";
import { lazy, Suspense } from "react";
import Spinner from "./components/spinner/spinner";

const Work = lazy(() => import("./pages/Work"));
const About = lazy(() => import("./pages/About"));
const Snippets = lazy(() => import("./pages/Snippets"));
const Blog = lazy(() => import("./pages/Blog"));

const WorkDetail = lazy(() => import("./pages/WorkDetail"));
const PostPage = lazy(() => import("./pages/PostPage"));
const SnippetsDetail = lazy(() => import("./pages/SnippetsDetail"));
const NotFound = lazy(() => import("./pages/NotFound"));

export const ThemeContext = createContext();
export const LightContext = createContext();

function App() {
  const fallback = (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner></Spinner>
    </div>
  );
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/work",
      element: (
        <Suspense fallback={fallback}>
          <Work />
        </Suspense>
      ),
    },
    {
      path: "/work/:id",

      element: (
        <Suspense fallback={fallback}>
          <WorkDetail />
        </Suspense>
      ),
    },
    {
      path: "/snippets",
      element: (
        <Suspense fallback={fallback}>
          <Snippets />
        </Suspense>
      ),
    },
    {
      path: "/snippets/:id",

      element: (
        <Suspense fallback={fallback}>
          <SnippetsDetail />
        </Suspense>
      ),
    },
    {
      path: "/blog",
      element: (
        <Suspense fallback={fallback}>
          <Blog />
        </Suspense>
      ),
    },
    {
      path: "/blog/:id",
      element: (
        <Suspense fallback={fallback}>
          <PostPage />
        </Suspense>
      ),
    },
    {
      path: "/about",
      element: (
        <Suspense fallback={fallback}>
          <About />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: (
        <Suspense fallback={fallback}>
          <NotFound />
        </Suspense>
      ),
    },
  ]);
  const [theme, setTheme] = useState("system");
  const [light, setLight] = useState(true);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <LightContext.Provider value={{ light, setLight }}>
        {light ? <div className={styles.appBackground}></div> : <></>}
        <RouterProvider router={router}></RouterProvider>
        <Footer />
      </LightContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
