import styles from "./footer.module.css";
import Link from "../link/Link";

function Footer() {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <footer className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <p className={styles.footerParagraph}>
          Follow me on <Link href="https://github.com/aweell"> Github</Link> and{" "}
          <Link href="https://twitter.com/aweelllll">Twitter</Link> or mail me
          at{" "}
          <Link href="mailto:hey.alexbuenodesign@gmail.com">
            hey.alexbuenodesign@gmail.com
          </Link>
        </p>
        <span className={styles.footerSubParagraph}>
          &copy;
          {year} - Designed and developed by <b>Alex Bueno</b>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
