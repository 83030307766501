import styles from "./flex.module.css";

function Flex(props) {
  const Flex = props.as || "div";

  return (
    <Flex
      className={`${styles.container}`}
      style={{
        flexDirection: `${props.direction}`,
        gap: `var(--spacing-${props.gap})`,
        justifyContent: `${props.justify}`,
        alignItems: `${props.align}`,
      }}
    >
      {props.children}
    </Flex>
  );
}

export default Flex;
