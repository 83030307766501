import styles from "./ambientSwitch.module.css";
import React, { useContext } from "react";
import { LightContext } from "../../App";
import Icon from "../Icon/Icon";

function AmbientSwitch() {
  const { light, setLight } = useContext(LightContext);
  const handleLight = () => setLight(!light);

  return (
    <button
      className={`${styles.ambientButton} 
       `}
      onClick={handleLight}
      aria-pressed={light ? true : false}
    >
      <Icon prefix name={light ? "flashlightOff" : "flashlightOn"} />
      {light ? "Turn off ambient light" : "Turn on ambient light"}
      {light ? (
        <>
          <div
            className={[
              styles.ambientColorPickerUno,
              styles.ambientColorPicker,
            ].join(" ")}
            style={{
              backgroundColor: "var(--gradient-tint-04)",
            }}
          ></div>
          <div
            className={[
              styles.ambientColorPickerDos,
              styles.ambientColorPicker,
            ].join(" ")}
            style={{
              backgroundColor: "var(--gradient-tint-01)",
            }}
          ></div>
        </>
      ) : (
        ""
      )}
    </button>
  );
}

export default AmbientSwitch;
