import styles from "./icon.module.css";

function Icon(props) {
  return (
    <svg
      style={{ width: props.width, fill: props.fill }}
      className={[
        (() => {
          if (props.suffix === true) {
            return styles.suffix;
          } else if (props.prefix === true) {
            return styles.prefix;
          } else {
            return "";
          }
        })(),
        styles.icon,
      ].join(" ")}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={props.viewBox ?? "0 0 20 20"}
    >
      {props.customPath ?? undefined}
      {(() => {
        if (props.name === "expand") {
          return (
            <path d="m10 13.062-5-5L6.062 7 10 10.938 13.938 7 15 8.062Z" />
          );
        }
        if (props.name === "light") {
          return (
            <path d="M10 12.5q1.042 0 1.771-.729.729-.729.729-1.771 0-1.042-.729-1.771Q11.042 7.5 10 7.5q-1.042 0-1.771.729Q7.5 8.958 7.5 10q0 1.042.729 1.771.729.729 1.771.729Zm0 1.5q-1.667 0-2.833-1.167Q6 11.667 6 10q0-1.667 1.167-2.833Q8.333 6 10 6q1.667 0 2.833 1.167Q14 8.333 14 10q0 1.667-1.167 2.833Q11.667 14 10 14Zm-8.25-3.25q-.312 0-.531-.219Q1 10.312 1 10q0-.312.219-.531.219-.219.531-.219h2q.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219Zm14.5 0q-.312 0-.531-.219-.219-.219-.219-.531 0-.312.219-.531.219-.219.531-.219h2q.312 0 .531.219Q19 9.688 19 10q0 .312-.219.531-.219.219-.531.219ZM10 4.5q-.312 0-.531-.219-.219-.219-.219-.531v-2q0-.312.219-.531Q9.688 1 10 1q.312 0 .531.219.219.219.219.531v2q0 .312-.219.531-.219.219-.531.219ZM10 19q-.312 0-.531-.219-.219-.219-.219-.531v-2q0-.312.219-.531.219-.219.531-.219.312 0 .531.219.219.219.219.531v2q0 .312-.219.531Q10.312 19 10 19ZM5.042 6.104 4 5.042q-.229-.209-.229-.511 0-.302.229-.531.208-.229.521-.229.312 0 .521.229l1.062 1.042q.229.229.229.531 0 .302-.229.531-.208.229-.521.229-.312 0-.541-.229ZM14.958 16l-1.062-1.042q-.229-.229-.229-.531 0-.302.229-.531.208-.229.521-.229.312 0 .541.229L16 14.958q.229.209.229.511 0 .302-.229.531-.229.229-.521.229-.291 0-.521-.229Zm-1.062-9.896q-.229-.208-.229-.521 0-.312.229-.541L14.958 4q.23-.229.521-.219.292.011.521.219.229.229.229.521 0 .291-.229.521l-1.042 1.062q-.229.229-.531.229-.302 0-.531-.229ZM4 16q-.229-.208-.229-.521 0-.312.229-.521l1.042-1.062q.229-.208.531-.208.302 0 .531.208.229.229.219.531-.011.302-.219.531L5.042 16q-.209.229-.511.229-.302 0-.531-.229Zm6-6Z" />
          );
        }
        if (props.name === "dark") {
          return (
            <path d="M10 17q-2.917 0-4.958-2.042Q3 12.917 3 10q0-2.917 2.042-4.958Q7.083 3 10 3q.271 0 .531.021.261.021.531.062-.812.605-1.291 1.5-.479.896-.479 1.917 0 1.771 1.218 2.99 1.219 1.218 2.99 1.218 1.021 0 1.917-.479.895-.479 1.5-1.291.041.27.062.531.021.26.021.531 0 2.917-2.042 4.958Q12.917 17 10 17Zm0-1.5q1.708 0 3.104-.979 1.396-.979 2.042-2.563-.417.104-.823.177-.406.073-.823.073-2.375 0-4.042-1.666Q7.792 8.875 7.792 6.5q0-.417.073-.823.073-.406.177-.823-1.584.646-2.563 2.042Q4.5 8.292 4.5 10q0 2.292 1.604 3.896T10 15.5Zm-.292-5.208Z" />
          );
        }
        if (props.name === "system") {
          return (
            <path d="M1.75 17q-.312 0-.531-.219Q1 16.562 1 16.25q0-.312.219-.531.219-.219.531-.219h16.5q.312 0 .531.219.219.219.219.531 0 .312-.219.531-.219.219-.531.219Zm1.75-2.5q-.625 0-1.062-.438Q2 13.625 2 13V4.5q0-.625.438-1.062Q2.875 3 3.5 3h13q.625 0 1.062.438Q18 3.875 18 4.5V13q0 .625-.438 1.062-.437.438-1.062.438Zm0-1.5h13V4.5h-13V13Zm0 0V4.5 13Z" />
          );
        }
        if (props.name === "flashlightOn") {
          return (
            <path d="M7 18v-8L5 7V2h10v5l-2 3v8Zm3-5.5q-.417 0-.708-.292Q9 11.917 9 11.5t.292-.708q.291-.292.708-.292t.708.292q.292.291.292.708t-.292.708q-.291.292-.708.292Zm-3.5-8h7v-1h-7Zm7 1.5h-7v.542l2 3V16.5h3V9.542l2-3ZM10 10Z" />
          );
        }
        if (props.name === "flashlightOff") {
          return (
            <path d="m16 18.125-3-3V18H7V9.104L1.875 3.979l1.063-1.062 14.124 14.145ZM8.5 16.5h3v-2.875l-3-3Zm4.5-5.625-1.438-1.437L13.5 6.542V6H8.125l-1.5-1.5H13.5v-1H5.625L5 2.875V2h10v5l-2 3Zm-3.062 1.25Zm-.021-4.333Z" />
          );
        }
        if (props.name === "externalLink") {
          return (
            <path d="M4.5 17q-.625 0-1.062-.438Q3 16.125 3 15.5v-11q0-.625.438-1.062Q3.875 3 4.5 3H10v1.5H4.5v11h11V10H17v5.5q0 .625-.438 1.062Q16.125 17 15.5 17Zm3.562-4L7 11.938 14.438 4.5H12V3h5v5h-1.5V5.562Z" />
          );
        }
        if (props.name === "lineStart") {
          return (
            <path d="M320-320q66 0 113-47t47-113q0-66-47-113t-113-47q-66 0-113 47t-47 113q0 66 47 113t113 47Zm0 80q-100 0-170-70T80-480q0-100 70-170t170-70q90 0 156.5 57T557-520h323v80H557q-14 86-80.5 143T320-240Zm0-240Z" />
          );
        }
        if (props.name === "arrowBack") {
          return (
            <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
          );
        }
        if (props.name === "arrowForward") {
          return (
            <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
          );
        }
        if (props.name === "calendar") {
          return (
            <path d="M580-240q-42 0-71-29t-29-71q0-42 29-71t71-29q42 0 71 29t29 71q0 42-29 71t-71 29ZM200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" />
          );
        }
        if (props.name === "eventUpcoming") {
          return (
            <path d="M600-80v-80h160v-400H200v160h-80v-320q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H600ZM320 0l-56-56 103-104H40v-80h327L264-344l56-56 200 200L320 0ZM200-640h560v-80H200v80Zm0 0v-80 80Z" />
          );
        }
        if (props.name === "language") {
          return (
            <path d="M480 976q-82 0-155-31.5t-127.5-86Q143 804 111.5 731T80 576q0-83 31.5-155.5t86-127Q252 239 325 207.5T480 176q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880 576q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480 976Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322 736H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584 878ZM170 656h136q-3-20-4.5-39.5T300 576q0-21 1.5-40.5T306 496H170q-5 20-7.5 39.5T160 576q0 21 2.5 40.5T170 656Zm216 0h188q3-20 4.5-39.5T580 576q0-21-1.5-40.5T574 496H386q-3 20-4.5 39.5T380 576q0 21 1.5 40.5T386 656Zm268 0h136q5-20 7.5-39.5T800 576q0-21-2.5-40.5T790 496H654q3 20 4.5 39.5T660 576q0 21-1.5 40.5T654 656Zm-16-240h118q-29-50-72.5-87T584 274q18 33 31.5 68.5T638 416Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376 274q-56 18-99.5 55T204 416Z" />
          );
        }
        if (props.name === "spotify") {
          return (
            <path d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z" />
          );
        }
        if (props.name === "twitch") {
          return (
            <g>
              <path d="M500,0L0,500v1800h600v500l500-500h400l900-900V0H500z M2200,1300l-400,400h-400l-350,350v-350H600V200h1600    V1300z" />
              <rect x="1700" y="550" width="200" height="600" />
              <rect x="1150" y="550" width="200" height="600" />
            </g>
          );
        }
        if (props.name === "codepen") {
          return (
            <path d="M100 34.2c-.4-2.6-3.3-4-5.3-5.3-3.6-2.4-7.1-4.7-10.7-7.1-8.5-5.7-17.1-11.4-25.6-17.1-2-1.3-4-2.7-6-4-1.4-1-3.3-1-4.8 0-5.7 3.8-11.5 7.7-17.2 11.5L5.2 29C3 30.4.1 31.8 0 34.8c-.1 3.3 0 6.7 0 10v16c0 2.9-.6 6.3 2.1 8.1 6.4 4.4 12.9 8.6 19.4 12.9 8 5.3 16 10.7 24 16 2.2 1.5 4.4 3.1 7.1 1.3 2.3-1.5 4.5-3 6.8-4.5 8.9-5.9 17.8-11.9 26.7-17.8l9.9-6.6c.6-.4 1.3-.8 1.9-1.3 1.4-1 2-2.4 2-4.1V37.3c.1-1.1.2-2.1.1-3.1 0-.1 0 .2 0 0zM54.3 12.3 88 34.8 73 44.9 54.3 32.4V12.3zm-8.6 0v20L27.1 44.8 12 34.8l33.7-22.5zM8.6 42.8 19.3 50 8.6 57.2V42.8zm37.1 44.9L12 65.2l15-10.1 18.6 12.5v20.1zM50 60.2 34.8 50 50 39.8 65.2 50 50 60.2zm4.3 27.5v-20l18.6-12.5 15 10.1-33.6 22.4zm37.1-30.5L80.7 50l10.8-7.2-.1 14.4z"></path>
          );
        }

        if (props.name === "kofi") {
          return (
            <path d="M31.844 11.932c-1.032-5.448-6.48-6.125-6.48-6.125h-24.4c-0.808 0-0.907 1.063-0.907 1.063s-0.109 9.767-0.027 15.767c0.22 3.228 3.448 3.561 3.448 3.561s11.021-0.031 15.953-0.067c3.251-0.568 3.579-3.423 3.541-4.98 5.808 0.323 9.896-3.776 8.871-9.219zM17.093 16.615c-1.661 1.932-5.348 5.297-5.348 5.297s-0.161 0.161-0.417 0.031c-0.099-0.073-0.14-0.12-0.14-0.12-0.595-0.588-4.491-4.063-5.381-5.271-0.943-1.287-1.385-3.599-0.119-4.948 1.265-1.344 4.005-1.448 5.817 0.541 0 0 2.083-2.375 4.625-1.281 2.536 1.095 2.443 4.016 0.963 5.751zM25.323 17.251c-1.24 0.156-2.244 0.036-2.244 0.036v-7.573h2.359c0 0 2.631 0.735 2.631 3.516 0 2.552-1.313 3.557-2.745 4.021z " />
          );
        } else {
          return undefined;
        }
      })()}
    </svg>
  );
}

export default Icon;
