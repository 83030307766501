import styles from "./menu.module.css";
import List from "../list/List";
import { links } from "../../data/workData";
import { NavLink } from "react-router-dom";
import ThemeToggle from "../themeToggle/ThemeToggle";
import Divider from "../divider/Divider";
import Tag from "../tag/tag";

function Menu(props) {
  return (
    <>
      <div className={styles.container} role="menu">
        <ul className={styles.themeSelect}>
          <li className={styles.themeRow}>
            <span className={styles.themeLabel}>Theme</span>
            <ThemeToggle></ThemeToggle>
          </li>
        </ul>
        <Divider contained />
        <List>
          {links.map((value, index) => (
            <li key={index} role="presentation">
              <NavLink
                role="menuitem"
                to={value.url}
                className={({ isActive }) =>
                  isActive ? `${styles.active} ${styles.navRow}` : styles.navRow
                }
              >
                <span className={styles.navText}>{value.label}</span>
                {value.new && (
                  <Tag inverse color="var(--link-color)">
                    New
                  </Tag>
                )}
              </NavLink>
            </li>
          ))}
        </List>
      </div>
    </>
  );
}

export default Menu;
