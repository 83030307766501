import { Posts, Miniatures, MiniaturesWebp } from "../pages/blog/posts";

export const posts = [
  {
    year: 2021,
    title: "Multi-expand vs auto-collapse accordions",
    timeStamp: "20210508T000000+0100",
    date: "May 8, 2021",
    readTime: "4 min read",
    detail: undefined,
    url: "https://bootcamp.uxdesign.cc/multi-expand-vs-auto-collapse-accordions-2d51aaec69ed",
    external: true,
    miniature: Miniatures.accordion,
    miniatureWebp: MiniaturesWebp.accordion,
    alt: "Image of a futuristic accordion",
  },
  {
    year: 2021,
    title: "Design tokens automation pipeline",
    timeStamp: "20210927T000000+0100",
    date: "Sep 27, 2021",
    readTime: "6 min read",
    detail: "From design tools to component libraries.",
    url: "https://alexbuenodesign.medium.com/design-tokens-automation-pipeline-from-design-tools-to-cdks-part-1-aac045c8fb15",
    external: true,
    miniature: Miniatures.pipeline,
    miniatureWebp: MiniaturesWebp.pipeline,
    alt: "Image of a pipe system with a lot of pipes in the wall of a building",
  },
  {
    year: 2022,
    title: "The good, the bad and the toggle",
    timeStamp: "20220102T000000+0100",
    date: "Jan 2, 2022",
    readTime: "6 min read",
    detail:
      "A dissection of the toggle, checkbox, radio button, and related components.",
    url: "the-good-the-bad-and-the-toggle",
    miniature: Miniatures.toggle,
    miniatureWebp: MiniaturesWebp.toggle,
    alt: "Image of multiple light switches in an empty room",
    component: Posts.toggle,
  },
  {
    year: 2022,
    type: ["design", "systems"],
    title: "State and <main>",
    timeStamp: "20220128T000000+0100",
    date: "Jan 28, 2022",
    readTime: "7 min read",
    detail:
      "Providing feedback to the user action: a tour of the different UI component states.",
    url: "state-and-main",
    miniature: Miniatures.state,
    miniatureWebp: MiniaturesWebp.state,
    alt: "Image of an neon arrow sign in a road",
    component: Posts.state,
  },
  {
    year: 2022,
    type: ["systems"],
    title: "The euclidean design model",
    timeStamp: "20220409T000000+0200",
    date: "Apr 9, 2022",
    readTime: "9 min read",
    detail:
      "A tri-dimensional abstraction model for interface design system thinking.",
    url: "the-euclidean-design-model",
    miniature: Miniatures.euclidean,
    miniatureWebp: MiniaturesWebp.euclidean,
    alt: "Image of a geometric shape on top of a pedestal",
    component: Posts.euclidean,
  },
  {
    year: 2022,
    type: ["design", "systems"],
    title: "Lost in customization",
    timeStamp: "20221109T000000+0100",
    date: "Nov 9, 2022",
    readTime: "8 min read",
    detail:
      "Design a flexible customization architecture for multi-brand design systems.",
    url: "lost-in-customization",
    miniature: Miniatures.lost,
    miniatureWebp: MiniaturesWebp.lost,
    alt: "Image of a person lost in a big city",
    component: Posts.lost,
  },
  {
    year: 2023,
    type: ["design", "systems"],
    title: "Building Mística tokens",
    timeStamp: "20221109T000000+0100",
    date: "May 7, 2023",
    readTime: "5 min read",
    detail:
      "Simplifying Design Token Management for Mística, Telefónica's Design System.",
    url: "building-mistica-tokens",
    miniature: Miniatures.misticaTokens,
    miniatureWebp: MiniaturesWebp.misticaTokens,
    alt: "Image of a tree map of multiple data representing the design tokens of Mística",
    component: Posts.misticaTokens,
  },
];
