import styles from "./chip.module.css";

function Chip(props) {
  return (
    <div
      className={styles.chipContainer}
      style={{
        marginLeft: props.aligned ? "-8px" : undefined,
        color: props.color ? props.color : undefined,
      }}
    >
      <span>{props.children}</span>
    </div>
  );
}

export default Chip;
